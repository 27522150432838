export const role = {
  key: 'custom:Role',
  value: 'Admin'
}

export const collectionTypes = {
  baseSys: 'global-system',
  sys: 'system-tenant-mapped',
  tenant: 'tenant',
  userPref: 'user-pref'
}

export const CollectionSortDict: any[] = [
  { sortKey: 'Highest percent cashback', itemKey: 'percentageCashback', reverse: true, id: 1 },
  { sortKey: 'Relevance', itemKey: 'relevance', reverse: false, id: 0 },
  { sortKey: 'Highest total cashback', itemKey: 'cashback', reverse: true, id: 2 },
  { sortKey: 'Price low to high', itemKey: 'price', reverse: false, id: 4 },
  { sortKey: 'Price high to low', itemKey: 'price', reverse: true, id: 5 },
]

export const collectionTypesDict = {
  system: 'System',
  tenant: 'Tenant',
  userpref: 'UserPreferenced',
  subtenant: 'SubTenant'
}

export const customOptions = [
  { key: 'DefaultSort', value: 'Relevance', type: 'dropdown', options: CollectionSortDict.map((x: any) => x.sortKey) },
  { key: 'IsActive', value: 'Yes', type: 'dropdown', options: ['Yes', 'No'] },
  { key: 'IsCard', value: 'No', type: 'dropdown', options: ['Yes', 'No'] },
  // { key: 'Level', value: 'Tenant', type: 'dropdown', options: [collectionTypesDict.subtenant,collectionTypesDict] },
]
