import { WithdrawType } from "../constant/enum"
import { TransferStatus } from "../enum"

export const countryCode:any = {
  US :'United States'
}
export const hotelLocationCode:any = {
  'las vegas' : 649196,
  'orlando' :646577,
  'new york' : 644158
}

export const purchaseHistoryPlaceHolderImg = "../../../../../assets/icons/purchase-history-placeholder.svg"

export const purchaseHistorySubCategory = {
  hotel:'Hotel',
  carRental:'Car Rental',
  themePark:'Theme Parks',
  thingsToDo:'Things To Do',
  flight: 'Flight',
  transfer: 'Transfer'
}

export const CashbackStatusLabelMap ={
  pending:'Pending',
  confirmed:'Approved',
  refund:'Canceled',
  notApplicable:'No cashback',
  paid:'Delivered'
}
export const CashbackStatusKeyMap ={
  pending:'pending',
  confirmed:'approved',
  refund:'cancelled',
  notApplicable:'notApplicable',
  paid:'paid',

}
export const purchaseInfoHeader = `Don’t see a purchase?`
export const purchaseToolTipInfo = `Don't see your purchase? Don't panic! It may take 24 hours to confirm your purchase, but as soon as we do we'll add your pending cashback here.`

export const purchaseFilterOption = [
  { name: 'All', code: 'All' },
  { name: CashbackStatusLabelMap.paid, code: CashbackStatusKeyMap.paid },
  { name: CashbackStatusLabelMap.pending, code: CashbackStatusKeyMap.pending },
  { name: CashbackStatusLabelMap.confirmed, code: CashbackStatusKeyMap.confirmed },
  { name: CashbackStatusLabelMap.refund, code: CashbackStatusKeyMap.refund },
  { name: CashbackStatusLabelMap.notApplicable, code: CashbackStatusKeyMap.notApplicable }
];

export const purchaseHistoryCashbackStatus = [
  { label: CashbackStatusLabelMap.paid, checked: false, value: CashbackStatusKeyMap.paid, key:'cashBackStatus' },
  { label: CashbackStatusLabelMap.pending, checked: false, value: CashbackStatusKeyMap.pending, key:'cashBackStatus' },
  { label: CashbackStatusLabelMap.confirmed, checked: false, value: CashbackStatusKeyMap.confirmed, key:'cashBackStatus' },
  { label: CashbackStatusLabelMap.refund, checked: false, value: CashbackStatusKeyMap.refund, key:'cashBackStatus' },
  { label: CashbackStatusLabelMap.notApplicable, checked: false, value: CashbackStatusKeyMap.notApplicable, key:'cashBackStatus' },

];

export const Paypal = {
  errorToast : `Failed to add ${WithdrawType.PayPal} account. Please try again later.`,
  sucessToast: WithdrawType.PayPal + ' account added.',
  bankName: WithdrawType.PayPal + ' account',
}

export class AppConstants {

  static ProductConstants = class {
    public static sorting = [
      {
        label: 'Most Recent',
        value: 'most_recent',
        isSelected: true,
        sortDirection: 'desc',
        reverse: true,
        key: 'addedDate',
        keyType: 'Date',
      },
      {
        label: 'Highest Cashback Percentage',
        value: 'cashback_percent',
        isSelected: false,
        sortDirection: 'desc',
        reverse: true,
        key: 'percentageCashback',
        keyType: 'number'
      },
      {
        label: 'Highest Cashback',
        value: 'highest_cashback',
        isSelected: false,
        sortDirection: 'desc',
        reverse: true,
        key: 'cashback',
        keyType: 'number'
      },
      {
        label: 'Price low to high',
        value: 'low_high',
        isSelected: false,
        sortDirection: 'asc',
        reverse: false,
        key: 'price',
        keyType: 'number'
      },
      {
        label: 'Price high to low',
        value: 'high_low',
        isSelected: false,
        sortDirection: 'desc',
        reverse: true,
        key: 'price',
        keyType: 'number'
      }
    ];
  };

  public static purchaseCashbackInfo = {
    refundInfo : `Since you canceled this purchase, we've removed the cashback amount you would have earned from your pending total.`,
    pendingInfo : 'Cashback is pending until your transaction is approved. Expect travel cashback within 30 days of your purchase. Expect shop cashback within 90 days of your purchase.',
    delivered : 'Cashback from this purchase was delivered to the payment method you used to pay for your travel. This typically happens within 5 days of booking.',
    dropShipPendingInfo : 'Cashback is pending until your transaction is approved. Expect travel cashback within 30 days of your purchase. Expect shop cashback 30-90 days after your purchase.',

  }

  public static walletInfo = {
    withdrawalFee : 0.25,
    minimumWithdrawalAmount: 10
  }

  public static homePageQuickAccessData = [
    {
      title: 'Earn Cashback',
      img: 'cashback.svg',
      content: 'Delivered right to your card on select travel purchases',
      link: '/cashback',
      buttonText: 'Learn More'
    },
  ];

  public static otpTimer = 60
  public static signUpPageQuote = `Our goal is simple: cashback on every purchase, for you to spend however and wherever you like. We’re here to turn your purchases into a personal payday.`

  public static gettingStartedMainAttractionContent = [
    {
      title: 'Browse savings',
      link: 'browseSavings'
    },
    {
      title: 'Buy directly',
      link: 'buyDirectly'
    },
    {
      title: 'Collect cashback',
      link: 'collectCashback'
    },
    {
      title: 'Cash out',
      link: 'cashOut'
    }
  ]
  public static featuredTitle = {
    featuredBannerTitle: 'Featured Banner',
    featuredTravelTitle: 'Featured Travel',
  };
  public static subscriptionIdentifiers = {
    signInComponent: 'signIn',
    signUpComponent: 'signUp',
    signupMiddlewareComponent: 'signUpMiddleware',
    // Travel component in Access module
    accessTravelComponent: 'accessTravel',
    authorizedHeaderComponent: 'authorizedHeader',
    homeComponent: 'home',
    footerComponent: 'footer',
    faqComponent: 'faq',
    faqContentComponent: 'faqContent',
    // Travel component in Travel module
    travelComponent: 'travel',
    carRentalComponent: 'carRental',
    travelCategoryTemplateComponent: 'travelCategory',
    brandDetailsComponent: 'brandDetails',
    cruisesComponent: 'cruises',
    diningComponent: 'dining',
    entertainmentComponent: 'entertainment',
    shoppingComponent: 'shopping',
    wellnessComponent: 'wellness',
    profileComponent: 'profile',
    accountComponent: 'account',
    cookiesPolicyComponent: 'cookiesPolicy',
    licensesComponent: 'licenses',
    privacyPolicyComponent: 'privacyPolicy',
    termsComponent: 'terms',
    thirdPartyPolicyComponent: 'thirdPartyPolicy',
    supportComponent: 'support',
    yourTripsComponent: 'yourTrips',
    appLoaderComponent: 'appLoader',
    breadcrumbComponent: 'breadcrumb',
    filtersComponent: 'filters',
    offersCardComponent: 'offersCard',
    offersCardModalComponent: 'offersCardModal',
    searchBannerComponent: 'searchBanner',
    imageBannerService: 'imageBannerService',
    geoLocationService: 'geoLocationService',
  };

  public static termsOfUse = String.raw`<p>Welcome to LaaSy! LaaSy is a next-generation loyalty platform that
  enables organizations to <b>inspire and reward loyalty</b> with a white-label solution for mobile and web that adapts
   to your organization’s mission, brand, and unique community of users. The LaaSy website, the LaaSy loyalty
    portal and all services provided by LaaSy through the website and portal  are collectively referred to
    herein as the "Service".</br>The Service is a copyrighted work belonging to LaaSy, Inc.
    ("LaaSy", "us", "our", and "we"). Certain features of
    the Service may be subject to additional guidelines, terms, or rules, which will be posted on the Service
     in connection with such features. All such additional terms, guidelines, and rules are incorporated by
      reference into these Terms.</br>THESE TERMS OF USE, OUR TERMS OF SALE AND OUR PRIVACY POLICY (THESE "TERMS")
      SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SERVICE.</br> THESE TERMS REQUIRE
      THE USE OF ARBITRATION (SECTION 14.2) ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS
       OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.</p>`;
}

export class CognitoResponseCode {
  static UserNotConfirmedException = 'UserNotConfirmedException';
  static NotAuthorizedException = 'NotAuthorizedException';
  static UsernameExistsException = 'UsernameExistsException';
  static CodeDeliveryFailureException = 'CodeDeliveryFailureException';
  static InvalidPasswordException = 'InvalidPasswordException';
  static CodeMismatchException = 'CodeMismatchException';
  static Success = 'SUCCESS';
}

export const cashbackFAQLink = 'https://support.laasyperks.com/en/collections/10597683-cashback';

export const cashbackCardItems = [
  {
    type: 'travel',
    title: 'Cashback on hotels',
    description: 'Go on, book the presidential suite. You can afford it now. Pro-tip: You get more cashback for prepaid bookings.',
    buttonText: 'Start your booking here',
    link: 'Hotel/Search',
    iconName: 'hotels'
  },
  {
    type: 'travel',
    title: 'Get paid to fly',
    description: `Cashback is the upgrade you've been waiting for with rewards on eligible airfare.`,
    buttonText: 'Book a flight today',
    link: 'Flight/Search',
    iconName: 'flights'
  },
  {
    type: 'travel',
    title: 'Earn rewards for car rentals',
    description: 'No wheeling and dealing – just cashback. Hit the road with us and accelerate your savings.',
    buttonText: 'Find a car rental',
    link: 'Car/Search',
    iconName: 'carRentals'
  },
  {
    type: 'travel',
    title: 'Cashback on airport transfers',
    description: 'Hire a driver with no hidden fees. Get paid while you cruise off in style.',
    buttonText: 'Reserve a transfer',
    link: 'Transfer/Search',
    iconName: 'transfers'
  },
  {
    type: 'shopping',
    title: 'Shop your favorite brands',
    description: 'Use our activation link, buy directly from the retailer, and get the best cashback offer. Pro-tip: Avoid discount browser extensions and coupon codes to make sure you get your cashback.',
    buttonText: 'Shop now',
    link: '',
    iconName: 'shop'
  }
];

export const purchaseTableColumns = [
  { name: 'Description', key: 'Description', sortable: true },
  { name: 'Date', key: 'Date', sortable: true },
  { name: 'Type', key: 'Type', sortable: true, dataCellClass: 'cell-type' },
  { name: 'Amount', key: 'Amount', customRender: 'Amount', sortable: true },
  { name: 'Receipt', key: 'Receipt', customRender: 'Receipt' },
  { name: 'Cashback', key: 'Cashback', customRender: 'Cashback', sortable: true }
];

export const countryOption = [
  { name: ' United States', code: 'United States' },
];

export const ageRangeOptions = [
  { name: '18-24', value: '18-24' },
  { name: '25-34', value: '25-34' },
  { name: '35-44', value: '35-44' },
  { name: '45-54', value: '45-54' },
  { name: '55-64', value: '55-64' },
  { name: '65+', value: '65+' }
]

export const genderOptions = [
  { name: 'Male', value:'Male' },
  { name: 'Female', value: 'Female' },
  { name: 'Other/Prefer not to answer',  value: 'Others' }
]

export const nonCognitoFields = [
  'ageRange',
  'gender',
  'zipCode',
  'country'
]

export const themeParks = [
  {
    name: 'Walt Disney World',
    view: 'wdw',
    image: '/assets/images/walt-disney-world.jpg',
    state: 'Orlando, FL'
  },
  {
    name: 'Disneyland',
    view: 'dl',
    state: 'Anaheim, CA',
    image: '/assets/images/disneyland.png'
  },
  {
    name: 'SeaWorld Orlando',
    view: 'sw',
    state: 'Orlando, FL',
    image: '/assets/images/seaworld.png'
  },
  {
    name: 'Universal Studio Orlando',
    view: 'uo',
    state: 'Orlando, FL',
    image: '/assets/images/universal-orlando.png'
  },
  {
    name: 'Universal Studios Hollywood',
    view: 'uh',
    state: 'Los Angeles, CA',
    image: '/assets/images/universal-hollywood.png'
  }
];

export interface CalendarDates {
  startDate: string;
  endDate: string;
}

export interface healthCardData {
  healthCardTitle: string;
  healthCardSubtitle: string;
  healthCardImageUrl: string;
  healthCardCTAButtonText: string;
}

export interface mobileAppCardData {
  mobileAppCardTitle: string;
  mobileAppCardSubtitle: string;
  mobileAppCardImageUrl: string;
  androidApp: string;
  iosApp: string;
}

export interface bannerData {
  bannerTitle: string,
  bannerSubtitle: string
}

export interface impactCardData {
  ctaText: string;
  ctaUri: string;
  description: string;
  imageUrl: string;
  title: string;
}

export type OTPTimer = {
  emailExpiryDurationInSeconds:number;
  emailResendEnableInSeconds:number;
  smsExpiryDurationInSeconds:number;
  smsResendEnableInSeconds:number;
}

export enum TravelType {
  Hotels,
  CarRentals,
  ThingsToDo,
  ThemePark,
  Flights
}

export const statusInfoMap: any = {
  [TransferStatus.pending]: 'This withdrawal is pending.',
  [TransferStatus.processed]: 'This withdrawal was completed.',
  [TransferStatus.failed]: 'An error occurred when attempting to withdraw your funds. Please try again. If this issue continues, please contact support.',
  [TransferStatus.canceled]:'This withdrawal has been canceled. Please try again. If this issue continues, please contact support.',
}

export const AstraStatusInfoMap = {
  ...statusInfoMap,
  [TransferStatus.verifying]:"Your destination account is being authorized. If we can't authorize your bank account within 24 hours, this withdrawal will be canceled and cashback will be returned to your wallet.",
  [TransferStatus.suspended]:"We've detected potentially fraudulent behavior on your account. For your security, we've disabled your ability to withdraw. If you believe this is an error, please contact support.",
  [TransferStatus.authFailed]:"Please try again and ensure the name, address, and phone number you provide during withdrawal match the details on the bank account you're trying to withdraw to.",

}

